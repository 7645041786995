import { useLocation, matchPath } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pageRoutes from './PageRoutes';
import React from "react";

const DynamicTitle = () => {
  const location = useLocation();
  const path = location.pathname;

  // Find the matching route
  const matchingRoute = pageRoutes.find(route =>
    matchPath(path, { path: route.path, exact: route.exact })
  );

  let pageTitle = 'Saver Asia App';

  if (matchingRoute) {
    const match = matchPath(path, {
      path: matchingRoute.path,
      exact: matchingRoute.exact,
    });

    // Check if the matched route has dynamic parameters for a custom title
    if (matchingRoute.path === '/compare/:currencyFrom/:countryFrom/:countryTo/:amount') {
      const { currencyFrom, countryFrom, countryTo } = match.params;
      pageTitle = `Compare Results for ${countryFrom} to ${countryTo} using ${currencyFrom}`;
    } /*else if(matchingRoute.path === '/page/:slug' || matchingRoute.path === '/compare/:slug') {
      const { slug } = match.params;
      pageTitle = slug;
    }*/ else {
      pageTitle = matchingRoute.title;
    }
  }

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default DynamicTitle;
